<template>
  <v-card id="membership-list">
    <!-- search -->
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <v-spacer></v-spacer>

      <div class="d-flex align-center pb-5">
        <!-- <v-text-field
          v-model="searchQuery"
          single-line
          dense
          outlined
          hide-details
          placeholder="Search Membership ID"
          class="membership-list-search me-3"
        ></v-text-field> -->

        <v-text-field
          v-model="emailQuery"
          single-line
          dense
          outlined
          hide-details
          placeholder="User email"
          class="membership-list-search me-3"
        ></v-text-field>

        <v-select
          v-model="statusFilter"
          :items="statusOptions"
          single-line
          outlined
          dense
          hide-details
          clearable
          placeholder="Select Status"
          class="membership-list-status"
        ></v-select>
      </div>
    </v-card-text>

    <!-- table -->
    <v-data-table
      v-model="selectedTableData"
      :headers="tableColumns"
      :items="membershipListTable"
      :options.sync="options"
      :server-items-length="totalMembershipListTable"
      :loading="loading"
      class="text-no-wrap"
    >
      <!-- id -->
      <!-- <template #[`item.id`]="{item}">
        <router-link
          class="font-weight-medium text-decoration-none"
          :to="{ name: 'membership-view', params: { id: item.id } }"
        >
          {{ item.membership_number }}
        </router-link>
      </template> -->

      <!-- user -->
      <template #[`item.user_id`]="{item}">
        <span
          class="font-weight-medium text-decoration-none"
          :to="{ name: 'user-view', params: { id: item.user_id } }"
        >
          {{ item.user.email }}
        </span>
      </template>

      <template #[`item.status`]="{item}">
        <span>{{ item.status }}</span>
      </template>

      <template #[`item.ending`]="{item}">
        <span>{{ item.ending ? 'No' : 'Yes' }}</span>
      </template>

      <!-- date -->
      <template #[`item.next_billing_at`]="{item}">
        <span class="text-no-wrap">{{ formatDate(item.next_billing_at) }}</span>
      </template>

      <!-- actions -->
      <template #[`item.actions`]="{item}">
        <div class="d-flex align-center justify-center">
          <!-- view  -->
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-bind="attrs"
                :to="{ name: 'medical-card-membership-overview', params: { id: item.id } }"
                v-on="on"
              >
                <v-icon size="18">
                  {{ icons.mdiEyeOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>View Membership</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mdiDeleteOutline, mdiDotsVertical, mdiEyeOutline, mdiPlus, mdiReceipt, mdiTrendingUp } from '@mdi/js'

import { t } from '@/plugins/i18n'
import { formatDate, price } from '@core/utils/filter'
import { ref } from '@vue/composition-api'

// composition function
import useMembershipList from './useMembershipList'

export default {
  filters: { price },
  setup() {
    const { membershipListTable, searchQuery, emailQuery, tableColumns, options, statusFilter, totalMembershipListTable, loading, selectedTableData } =
      useMembershipList()

    const statusOptions = ref(['wait_for_payment', 'expired', 'cancelled', 'wait_for_confirmation', 'confirmed', 'completed'])

    const selectedAction = ref('')

    const actionOptions = [
      // { title: 'Download', icon: mdiDownloadOutline },
      // { title: 'Edit', icon: mdiPencilOutline },
      // { title: 'Delete', icon: mdiDeleteOutline },
      // { title: 'Duplicate', icon: mdiContentCopy },
    ]

    const checkType = data => {
      if (typeof data === 'number') return 'number'
      if (typeof data === 'string') return 'string'

      return 0
    }

    return {
      tableColumns,
      searchQuery,
      emailQuery,
      statusOptions,
      statusFilter,
      options,
      totalMembershipListTable,
      membershipListTable,
      loading,
      selectedTableData,
      actionOptions,
      selectedAction,

      checkType,
      formatDate,

      t,

      icons: {
        mdiTrendingUp,
        mdiPlus,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiEyeOutline,
        mdiReceipt,
      },
    }
  },
}
</script>
