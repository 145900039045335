import { useTableOptions } from '@/composables'
import { fetchMemberships } from '@api/user'
import { ref, watch } from '@vue/composition-api'

const cached = []

export default function useMembershipList() {
  const membershipListTable = ref([])

  // Table Handlers
  const tableColumns = [
    {
      text: '#ID',
      align: 'start',
      value: 'id',
    },
    { text: 'User', value: 'user_id' },
    { text: 'Status', value: 'status' },
    { text: 'Renewal', value: 'ending' },
    { text: 'Next Billing Date', value: 'next_billing_at' },

    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const options = ref(
    cached[0] || {
      sortBy: ['id'],
      sortDesc: [true],
    },
  )
  const searchQuery = ref(cached[1] || '')
  const emailQuery = ref(cached[2] || '')
  const statusFilter = ref(cached[3] || null)
  const totalMembershipListTable = ref(0)
  const loading = ref(false)
  const selectedTableData = ref([])

  const loadMemberships = () =>
    fetchMemberships(
      useTableOptions(options.value, {
        search: searchQuery.value,
        email: emailQuery.value,

        // options: options.value,
        status: statusFilter.value,
      }),
    )
      .then(({ data }) => {
        const { records, pagination } = data.data
        membershipListTable.value = records
        totalMembershipListTable.value = pagination.total
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })

  const cachable = [options, searchQuery, emailQuery, statusFilter]

  const cache = () => {
    cachable.forEach((variable, index) => {
      cached[index] = variable.value
    })
  }

  watch(cachable, () => {
    const newPage = options.value.page

    const hasDiff = cachable.find((variable, index) => index > 0 && cached[index] !== variable.value)

    if (newPage >= 2 && hasDiff) {
      options.value.page = 1
    }

    loading.value = true
    selectedTableData.value = []
    loadMemberships()
    cache()
  })

  return {
    tableColumns,
    searchQuery,
    emailQuery,
    options,
    membershipListTable,
    statusFilter,
    totalMembershipListTable,
    loading,
    selectedTableData,
    loadMemberships,
  }
}
