var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "id": "membership-list"
    }
  }, [_c('v-card-text', {
    staticClass: "d-flex align-center flex-wrap pb-0"
  }, [_c('v-spacer'), _c('div', {
    staticClass: "d-flex align-center pb-5"
  }, [_c('v-text-field', {
    staticClass: "membership-list-search me-3",
    attrs: {
      "single-line": "",
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "placeholder": "User email"
    },
    model: {
      value: _vm.emailQuery,
      callback: function callback($$v) {
        _vm.emailQuery = $$v;
      },
      expression: "emailQuery"
    }
  }), _c('v-select', {
    staticClass: "membership-list-status",
    attrs: {
      "items": _vm.statusOptions,
      "single-line": "",
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "clearable": "",
      "placeholder": "Select Status"
    },
    model: {
      value: _vm.statusFilter,
      callback: function callback($$v) {
        _vm.statusFilter = $$v;
      },
      expression: "statusFilter"
    }
  })], 1)], 1), _c('v-data-table', {
    staticClass: "text-no-wrap",
    attrs: {
      "headers": _vm.tableColumns,
      "items": _vm.membershipListTable,
      "options": _vm.options,
      "server-items-length": _vm.totalMembershipListTable,
      "loading": _vm.loading
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.options = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item.user_id",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('span', {
          staticClass: "font-weight-medium text-decoration-none",
          attrs: {
            "to": {
              name: 'user-view',
              params: {
                id: item.user_id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(item.user.email) + " ")])];
      }
    }, {
      key: "item.status",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('span', [_vm._v(_vm._s(item.status))])];
      }
    }, {
      key: "item.ending",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('span', [_vm._v(_vm._s(item.ending ? 'No' : 'Yes'))])];
      }
    }, {
      key: "item.next_billing_at",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('span', {
          staticClass: "text-no-wrap"
        }, [_vm._v(_vm._s(_vm.formatDate(item.next_billing_at)))])];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('div', {
          staticClass: "d-flex align-center justify-center"
        }, [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref6) {
              var on = _ref6.on,
                attrs = _ref6.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "icon": "",
                  "small": "",
                  "to": {
                    name: 'medical-card-membership-overview',
                    params: {
                      id: item.id
                    }
                  }
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "size": "18"
                }
              }, [_vm._v(" " + _vm._s(_vm.icons.mdiEyeOutline) + " ")])], 1)];
            }
          }], null, true)
        }, [_c('span', [_vm._v("View Membership")])])], 1)];
      }
    }], null, true),
    model: {
      value: _vm.selectedTableData,
      callback: function callback($$v) {
        _vm.selectedTableData = $$v;
      },
      expression: "selectedTableData"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }